import api from "../axiosAuthClient";

export const verifyPayment = (id) => {
  return () => api.get(`/payment/${id}`).then((res) => res.data);
};

export const buyToken = (quantity, paymentMethod) => {
  return api.post("/token", { method: paymentMethod, quantity: quantity })
};

