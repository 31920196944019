import React from 'react';
import {ArrowRightIcon} from "lucide-react";
import useAuth from "../../../hooks/useAuth";

export default function MigrationPage() {
    const { user } =
        useAuth();

    return (
        <div className="relative flex h-screen flex-col overflow-hidden">
            <div className="flex flex-1 flex-col items-center justify-between pt-8 pb-5 md:flex-row md:pb-0">
                <img src="/images/controller.webp" alt="" className="h-auto w-1/2 max-w-xl flex-shrink-0 -translate-x-1/2 scale-150 object-contain md:w-1/4 md:translate-x-0 md:scale-100"/>

                <div className="mx-auto flex max-w-4xl flex-1 flex-col items-center justify-center gap-5 px-6 text-center md:w-2/4 relative z-50">
                    <h3 className="text-lg font-semibold text-black">Hi{user && ", "} {user?.displayName ?? 'there'} 👋</h3>
                    <h1 className="text-3xl font-bold tracking-tighter text-black md:text-5xl lg:text-7xl">Let’s make the switch to <span className="text-green-500">Soccersm v2</span></h1>
                    <p className="text-neutral-700 md:text-xl">Migrate to Soccersm 2.0 to enjoy our new features and updates to existing features </p>
                    <a className="flex cursor-pointer gap-2 btn-primary" href="https://alpha.soccersm.ai/?a=migrate">Get Started <ArrowRightIcon /></a>
                </div>
                <img src="/images/boy.webp" alt="" className="h-auto w-1/2 max-w-xl flex-shrink-0 translate-x-1/2 scale-150 object-contain md:w-1/4 md:translate-x-0 md:scale-100"/>
            </div>
            <img src="/images/switch.svg" alt="" className="absolute top-1/2 bottom-1/2 left-0 h-auto w-full -translate-y-1/2 font-bold text-neutral-300" />
            <img src="/images/migration-bg.webp" alt="" className="relative z-10 w-full"/>
        </div>
    );
}