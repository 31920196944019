import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";

import services from "../services";

import Notify from "../components/Notifications/Notify";

import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

import { useNavigate } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";

import { UserContext } from "./UserContext";
import { ConfigContext } from "./ConfigContext";

export const SlipContext = createContext();

const SlipFromLS = JSON.parse(localStorage.getItem("slip") || "[]");

const MAX_SLIP = 30;

const SlipContextProvider = (props) => {
  // retrieving values from local storage
  const [slip, setSlip] = useState(SlipFromLS);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { user, getUserData } = useContext(UserContext);
  const { config } = useContext(ConfigContext);

  const addSlipItem = (data) => {
    // check if it's already in slip
    const exist = slip.find((x) => x.id === data.id);
    if (slip.length < MAX_SLIP) {
      if (exist) {
        setSlip(slip.map((x) => (x.id === data.id ? { ...exist } : x)));
        Notify("info", "Added to slip");
      } else {
        setSlip([
          ...slip,
          {
            ...data,
          },
        ]);
        Notify("info", "Added to slip");
      }
      // SLIP IS FULL SO DO NOT ADD
    } else {
      //slip full
      Notify("error", "Slip is full (30 maximum)");
    }
  };

  const removeSlipItem = (code) => {
    setSlip(slip.filter((item) => item.id !== code));
  };

  // clear Slip
  const clearSlip = useCallback(() => {
    // clear Slip method
    setSlip([]);
  }, []);

  // useeffect to update local storage
  useEffect(() => {
    localStorage.setItem("slip", JSON.stringify(slip));
  }, [slip, clearSlip]);

  // formation here
  const [homeFormation, setHomeFormation] = useState(null);
  const [awayFormation, setAwayFormation] = useState(null);

  const requestPrediction = (
    setLoading,
    requestData,
    totalCoins,
    setIsOpen
  ) => {
    let tokens = user.token ? user.token : 0;
    let limit = config ? config?.tokenConfig.dailyFreeLimit : 0;

    // Calculate Tokens & Make Prediction
    if (totalCoins > tokens + limit) {
      setIsOpen(false);
      Notify("error", "Insufficient tokens");
    } else {
      // Go ahead and make prediction
      // Populate data into data array
      let data = [];

      // prepare data with params for backend
      for (let i = 0; i < requestData.length; i++) {
        // basic: didn't modify formation
        if (
          requestData[i].homeFormation === null &&
          requestData[i].awayFormation === null
        ) {
          data.push({
            id: requestData[i].id,
            homeTeam: requestData[i].homeTeamId,
            awayTeam: requestData[i].awayTeamId,
          });
        }

        // modified only home formation
        if (
          requestData[i].homeFormation !== null &&
          requestData[i].awayFormation === null
        ) {
          data.push({
            id: requestData[i].id,
            homeTeam: {
              id: requestData[i].homeTeamId,
              formation: requestData[i].homeFormation,
            },
            awayTeam: requestData[i].awayTeamId,
          });
        }

        // modified only away formation
        if (
          requestData[i].homeFormation === null &&
          requestData[i].awayFormation !== null
        ) {
          data.push({
            id: requestData[i].id,
            homeTeam: requestData[i].homeTeamId,
            awayTeam: {
              id: requestData[i].awayTeamId,
              formation: requestData[i].awayFormation,
            },
          });
        }

        // modified both home and away formation
        if (
          requestData[i].homeFormation !== null &&
          requestData[i].awayFormation !== null
        ) {
          data.push({
            id: requestData[i].id,
            homeTeam: {
              id: requestData[i].homeTeamId,
              formation: requestData[i].homeFormation,
            },
            awayTeam: {
              id: requestData[i].awayTeamId,
              formation: requestData[i].awayFormation,
            },
          });
        }
      }

      logEvent(analytics, "Prediction Request", {
        quantityType: "bulk",
        totalMatches: requestData.length,
        totalTokensCost: totalCoins,
        userOwnTokens: tokens,
        freeTokens: limit,
      });

      setLoading(true);
      services
        .requestPrediction(data)
        .then((res) => {
          setLoading(false);
          setIsOpen(false);

          // Get updated tokens
          queryClient.refetchQueries({ queryKey: ["user data"] });
          getUserData();

          clearSlip();

          // Direct user straight to Predictions page
          // navigate("/request-prediction/status", {
          //   state: {
          //     status: res.data,
          //   },
          // });

          navigate("/my-predictions");
        })
        .catch((e) => {
          setIsOpen(false);
          setLoading(false);
          getUserData();

          Notify(
            "error",
            e.response.data ? e.response.data.message : "An error occured"
          );
        });
    }
  };

  return (
    <SlipContext.Provider
      value={{
        homeFormation,
        requestPrediction,
        setHomeFormation,
        awayFormation,
        setAwayFormation,
        slip,
        addSlipItem,
        clearSlip,
        removeSlipItem,
      }}
    >
      {props.children}
    </SlipContext.Provider>
  );
};

export default SlipContextProvider;

// if (totalCoins > tokens + limit) {
//   // setIsOpen(false);
//   Notify("error", "Insufficient tokens");
// } else {
//   let info = [];

//   // basic: didn't modify formation
//   if (homeFormation === null && awayFormation === null) {
//     info.push({
//       id: data.id,
//       homeTeam: data.homeTeamId,
//       awayTeam: data.awayTeamId,
//     });
//   }

//   // modified only home formation
//   if (homeFormation !== null && awayFormation === null) {
//     info.push({
//       id: data.id,
//       homeTeam: { id: data.homeTeamId, formation: homeFormation },
//       awayTeam: data.awayTeamId,
//     });
//   }

//   // modified only away formation
//   if (homeFormation === null && awayFormation !== null) {
//     info.push({
//       id: data.id,
//       homeTeam: data.homeTeamId,
//       awayTeam: { id: data.awayTeamId, formation: awayFormation },
//     });
//   }

//   // modified both home and away formation
//   if (homeFormation !== null && awayFormation !== null) {
//     info.push({
//       id: data.id,
//       homeTeam: { id: data.homeTeamId, formation: homeFormation },
//       awayTeam: { id: data.awayTeamId, formation: awayFormation },
//     });
//   }

//   logEvent(analytics, "Prediction Request", {
//     quantityType: "single",
//     total: 1,
//     tokens_cost: totalCoins,
//     user_tokens: tokens,
//     free_tokens: limit,
//   });

//   setReqLoading(true);
//   services
//     .requestPrediction(info)
//     .then((res) => {
//       setReqLoading(false);
//       setIsOpen(false);
//       // fetch user data after prediction
//       getUserData();

//       navigate("/request-prediction/status", {
//         state: {
//           status: res.data,
//         },
//       });
//     })
//     .catch((e) => {
//       setIsOpen(false);
//       setReqLoading(false);
//       Notify("error", e.response.data?.message);
//     });
// }
