export const getToken = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user !== null) {
    return user.accessToken;
  } else {
    return 0;
  }
};

export const getRefreshToken = () => {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user !== null) {
    return user.tokenManager.refreshToken;
  } else {
    return 0;
  }
};


