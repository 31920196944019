import api from "../axiosAuthClient";

export const getTopLeagues = () => {
  // old style for app config call
  return api.get("/leagues/top");
};

export const getMatches = ({
  startDate,
  endDate,
  limit,
  page,
  status,
  selectedLeague,
}) => {
  return () =>
    api
      .get(
        selectedLeague
          ? `/matches/${status}?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&leagueId=${selectedLeague.id}`
          : `/matches/${status}?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`
      )
      .then((res) => res.data);
};

export const getSearch = ({ search, leagueId = null }) => {
  let url = leagueId
    ? `/utils/search?search=${search}&leagueId=${leagueId}`
    : `/utils/search?search=${search}`;
  return () => api.get(url).then((res) => res.data);
};

export const getSearchMatches = ({ type, id }) => {
  let url;
  if (type === "league") url = `/matches/upcoming?leagueId=${id}`;
  if (type === "country") url = `/matches/upcoming?countryId=${id}`;
  if (type === "team") url = `/matches/upcoming?teamId=${id}`;

  return () => api.get(url).then((res) => res.data);
};

export const getMatchInfo = (id) => {
  return () => api.get(`/matches/${id}/info`).then((res) => res.data);
};

export const getTeam = (id) => {
  return api.get(`/teams/${id}`);
};
