import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Vercel analytics
import { Analytics } from "@vercel/analytics/react";

// React Query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * (60 * 1000), // 2 mins
      cacheTime: 3 * (60 * 1000), // 3 mins
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />

      <Analytics />
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </StrictMode>
);
