import axios from "axios";

import { getToken, getRefreshToken } from "./localService";

import { firebaseConfig } from "../firebase";

// Notify
import Notify from "../components/Notifications/Notify";

const api = axios.create({
  baseURL: "https://api.soccersm.com",
});

// REQUEST INTERCEPTOR: Add Firebase tokens to authenticate requests
api.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        "X-Firebase-Token": getToken(),
      },
    };
  },
  (error) => Promise.reject(error)
);

// RESPONSE INTERCEPTOR: listen for a 401 or 403 then refresh token
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    // 403 error means the server understands but refuses to authorize because token is expired
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // log out if refresh token is undefined
      if (!Boolean(getRefreshToken())) {
        localStorage.setItem("user", null);
        sessionStorage.setItem("config", null);
        window.location.replace("/login");
        window.location.reload();

        Notify("error", "Please login to continue");
      }

      try {
        await fetch(
          `https://securetoken.googleapis.com/v1/token?key=${
            firebaseConfig.apiKey
          }&grant_type=refresh_token&refresh_token=${getRefreshToken()}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
          }
        )
          .then((response: any) => response.json())
          .then((data) => {
            // Update user in Local Storage
            const user = JSON.parse(localStorage.getItem("user"));
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                accessToken: data.access_token,
                tokenManager: {
                  refreshToken: data.refresh_token,
                  accessToken: data.access_token,
                  expirationTime: data.expires_in,
                },
              })
            );

            // return
            return axios({
              ...originalRequest,
              headers: {
                "X-Firebase-Token": getToken(),
              },
            });
          })
          .catch((e) => {
            // unable to refresh token so log user out
            console.log("Unable to refresh token", e);
            localStorage.setItem("user", null);
            sessionStorage.setItem("config", null);
            window.location.replace("/login");
            window.location.reload();
            Notify("error", "Please login to continue");
          });
      } catch (error) {
        // Handle refresh token error or redirect to login
        console.log("Error getting new token: ", error);
        localStorage.setItem("user", null);
        sessionStorage.setItem("config", null);
        window.location.replace("/login");
        window.location.reload();

        Notify("error", "Please login to continue");
      }
    }

    return Promise.reject(error);
  }
);

export default api;
