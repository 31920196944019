import api from "../axiosAuthClient";

export const getPredictions = ({
  limit = 10,
  startDate = null,
  endDate = null,
  page = 1,
  status = "done",
  search = null,
}) => {
  if (startDate !== null && search === null) {
    return () =>
      api
        .get(
          `/predictions?limit=${limit}&page=${page}&status=${status}&startDate=${new Date(
            startDate
          ).toISOString()}`
        )
        .then((res) => res.data);
  } else if (search !== null && startDate !== null) {
    return () =>
      api
        .get(
          `/predictions?limit=${limit}&page=${page}&search=${search}&status=${status}&startDate=${new Date(
            startDate
          ).toISOString()}`
        )
        .then((res) => res.data);
  } else if (search !== null && startDate === null) {
    return () =>
      api
        .get(
          `/predictions?limit=${limit}&page=${page}&status=${status}&search=${search}`
        )
        .then((res) => res.data);
  } else {
    return () =>
      api
        .get(`/predictions?limit=${limit}&page=${page}&status=${status}`)
        .then((res) => res.data);
  }
};

export const requestPrediction = (matches) => {
  return api.post("/predictions/request", matches);
};

export const getTodayPredictions = ({
  limit = 30,
  startDate = null,
  page = 1,
}) => {
  return () =>
    api
      .get(
        `/predictions?limit=${limit}&page=${page}&startDate=${new Date(
          startDate
        ).toISOString()}`
      )
      .then((res) => res.data);
};
