import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {


  ReactGA4.initialize("G-93DEM4XDXL");
};

const TrackGoogleAnalyticsEvent = (category, action, label) => {

  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };

