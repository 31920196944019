import React, { useEffect } from "react";

// router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Error Boundary
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./components/Fallback/Fallback";

// toast messages
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// comm

// tawk
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

// Contexts
import SlipContextProvider from "./contexts/SlipContext";
import ConfigContextProvider from "./contexts/ConfigContext";
import UserContextProvider from "./contexts/UserContext";
import { ThemeProvider, getInitialTheme } from "./contexts/ThemeContext";

// Routes
import PublicRoutes from "./routes/PublicRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";

// Broken / Non existent / 404 Route
import NotFound from "./components/Fallback/NotFound";

// Google Analytics Tracker
import InitializeGoogleAnalytics, {
  TrackGoogleAnalyticsEvent,
} from "./utils/analyticsTracker";

// Bugsnag Error Monitoring
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import MigrationPage from "./app/public/migration/MigrationPage";

// BETA UPDATES

function App() {
  // fake authentication for react app loader
  const authenticate = () => {
    return new Promise((resolve) => setTimeout(resolve, 500)); // 2 seconds
  };

  useEffect(() => {
    // Fake Loader
    authenticate().then(() => {
      const ele = document.getElementById("ipl-progress-indicator");
      if (ele) {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          // remove from DOM
          ele.remove();
        }, 500);
      }
    });

    // Initialize Google Analytics
    InitializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    // calling bugsnag once
    Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_KEY,
      plugins: [new BugsnagPluginReact()],
    });
  }, []);

  return (
    <BrowserRouter>
      <TawkMessengerReact
        propertyId="64780c1874285f0ec46ed980"
        widgetId="1h1qf2r5b"
      />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={(error) => {
          TrackGoogleAnalyticsEvent(error, "!ERROR: App Crash", error);
        }}
        onReset={() => {
          window.location.replace("/");
        }}
      >
        <UserContextProvider>
          <ConfigContextProvider>
            <SlipContextProvider>
              <ThemeProvider initialTheme="dark">
                <Routes>
                  {/*{AuthRoutes}*/}
                  {/*{PublicRoutes}*/}
                  {/*{DashboardRoutes}*/}
                  {/* Only migration page accepted */}
                  <Route path="/" element={<MigrationPage />} />,
                  {/* Not Found */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </ThemeProvider>
            </SlipContextProvider>
          </ConfigContextProvider>
        </UserContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
