
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyDso1Z8ol9QsOoRlr9vQGtGpbSnK18IY5M",
  authDomain: "soccersm-analytics.firebaseapp.com",
  projectId: "soccersm-analytics",
  storageBucket: "soccersm-analytics.appspot.com",
  messagingSenderId: "900518234957",
  appId: "1:900518234957:web:8958d5defdd8a7fdcf0504",
  measurementId: "G-93DEM4XDXL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const analytics = getAnalytics(app);



// social login providers
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

// Authentication functions

// Google Sign In
export function signInWithGoogle() {
  return signInWithPopup(auth, provider);
}

// Facebook Auth
export function signInWithFacebook() {
  return signInWithPopup(auth, facebookProvider);
}

// twitter auth
export function signInWithTwitter() {
  return signInWithPopup(auth, twitterProvider);
}

