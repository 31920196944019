import axios from "axios";

// Axios Client
const api = axios.create({
  baseURL: "https://api.soccersm.com",
});

// Non Authenticated / Public Requests
export const getTopMatches = () => {
  return () => api.get("/matches/upcoming/top").then((res) => res.data);
};

export const getPastTopMatches = () => {
  return () => api.get("/predictions/global").then((res) => res.data);
};

export const getPastCookedSlips = () => {
  return () => api.get(`/slips/passed`).then((res) => res.data);
};

export const queryTeams = (search, limit) => {
  return () =>
    api.get(`/teams?search=${search}&limit=${limit}`).then((res) => res.data);
};

export const compareTeams = (teamAID, teamBID) => {
  return () =>
    api.get(`/teams/compare/${teamAID}/${teamBID}`).then((res) => res.data);
};

export const getGlobalStatistics = () => {
  return () => api.get(`/predictions/global/stats`).then((res) => res.data);
};
