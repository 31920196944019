import React, { createContext, useState, useEffect } from "react";

import services from "../services";
import Notify from "../components/Notifications/Notify";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../hooks/useAuth";

export const ConfigContext = createContext();

const leaguesFromLS = JSON.parse(localStorage.getItem("leagues") || "[]");

// const configFromS = JSON.parse(localStorage.getItem("config") || null);

const ConfigContextProvider = (props) => {
  const { user } = useAuth();

  // Top Leagues
  const [leagues, setLeagues] = useState(leaguesFromLS);

  const getAllLeagues = () => {
    services.getTopLeagues().then((res) => {
      setLeagues(res.data);
    });
  };

  //
  const {
    data: config,
    refetch,
    error,
  } = useQuery({
    queryKey: ["app config"],
    queryFn: services.appConfig(),
    enabled: Boolean(user?.accessToken),
    staleTime: 1440 * (60 * 1000), // An entire day
    cacheTime: 1450 * (60 * 1000), // A little more than an entire day
  });

  const getAppData = () => {
    refetch();
  };

  if (error) {
    localStorage.setItem("user", null);
    sessionStorage.setItem("config", null);
    window.location.replace("/login");
    window.location.reload();
    Notify("error", "Please login to continue");
  }

  // USER AVAILALBE TOKENS
  const [userAvailableTokens, setUserAvailableTokens] = useState(null);
  const [userFreeQuota, setUserFreeQuota] = useState(null);

  useEffect(() => {
    //
    if (user && config) {
      // Free Quota Left
      setUserFreeQuota(
        Math.max(
          0,
          config?.tokenConfig.dailyFreeLimit - user.todayRequestsCount
        )
      );

      // User Available tokens : User tokens + Free tokens left
      setUserAvailableTokens(user.token);
    }

    // combine both
    // user.token +
    //   Math.max(0, config?.tokenConfig.dailyFreeLimit - user.todayRequestsCount)
  }, [user, config]);

  // update LS: Config
  useEffect(() => {
    if (config) {
      localStorage.setItem("config", JSON.stringify(config));
    } else {
      localStorage.setItem("config", null);
    }
  }, [config]);

  // Update LS - Leagues
  useEffect(() => {
    localStorage.setItem("leagues", JSON.stringify(leagues));
  }, [leagues]);

  return (
    <ConfigContext.Provider
      value={{
        config,
        getAppData,
        getAllLeagues,
        leagues,
        userAvailableTokens,
        userFreeQuota,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
