import React from "react";

import { BiMessageSquareError } from "react-icons/bi";

function Fallback({ resetErrorBoundary }) {
  return (
    <div className="absolute h-full w-full bg-white flex items-center justify-center px-10 dark:bg-primary-dark">
      <div className="">
        <BiMessageSquareError size={60} className="text-dark-green dark:text-light-green" />
        <h1 className="text-3xl md:text-4xl font-bold mb-3 mt-5 text-dark-green dark:text-light-green">
          Ooops. Something went wrong
        </h1>
        <p className="max-w-lg mb-10 text-gray-600 dark:text-gray-400">
          Our team has been notified and is actively addressing technical
          difficulties to ensure a smoother experience for you. Please consider
          <span className="font-bold text-black dark:text-light-green">
            {" "}
            refreshing the app and attempting again.
          </span>
        </p>
        <button className="btn-primary" onClick={resetErrorBoundary}>
          Refresh
        </button>
      </div>
    </div>
  );
}

export default Fallback;
