import * as localService from "./localService";

// Features
import * as publicService from "./features/publicService";

import * as appService from "./features/appService";
import * as predictionService from "./features/predictionService";

import * as userService from "./features/userService";
import * as paymentService from "./features/paymentService";

import * as matchAndTeamService from "./features/matchAndTeamService";

import * as cookedSlipsService from "./features/cookedSlipService";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...localService,
  ...publicService,
  ...appService,
  ...predictionService,
  ...userService,
  ...matchAndTeamService,
  ...paymentService,
  ...cookedSlipsService
};
