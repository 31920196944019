import api from "../axiosAuthClient";

export const getUserPredictionStatistics = () => {
  return () => api.get("/predictions/stats").then((res) => res.data);
};

export const user = () => {
  return () => api.get("/user").then((res) => res.data);
};

export const userTransactions = (status = "success", limit = 10, page = 1) => {
  return () =>
    api
      .get(`/user/transactions?status=${status}&limit=${limit}&page=${page}`)
      .then((res) => res.data);
};

// REWARDS

export const getUserRewards = (status, page = 1, limit = 10) => {
  return () =>
    api
      .get(`/user/reward?redeemed=${status}&limit=${limit}&page=${page}`)
      .then((res) => res.data);
};

export const getUserReferrals = () => {
  return () => api.get("/user/referrals").then((res) => res.data);
};

export const redeemRewards = () => {
  return api.post("/user/reward/redeem");
};

export const addReferral = (code) => {
  return api.post("/user/ancestor", { code });
};

export const joinNewsletter = (email) => {
  return api.post("/utils/newsletter", { email });
};
