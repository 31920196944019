import React from "react";

import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

// @ts-ignore
import speech from "../../assets/icons/speech.svg";

function NotFound() {
  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Soccersm | Page Not Found </title>
      </Helmet>

      <div className="dark:bg-primary-dark">
        <div className="md:mx-auto flex items-center justify-center h-screen text-center max-w-7xl">
          <div>
            <img
                src={speech}
                className="text-center mx-auto"
                alt="speech bubble"
            />
            <h2 className="text-5xl font-bold">Whew!</h2>
            <h2 className="w-3/5 mx-auto text-center mt-3 text-base mb-10">
              Seems the requested page is broken or doesn't exist
            </h2>
            <NavLink to="/">
              <button className="btn-primary">Back to Home</button>
            </NavLink>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </React.Fragment>
  );
}

export default NotFound;
