import api from "../axiosAuthClient";

import { AxiosResponse } from "axios"; // Import AxiosResponse from your axios library

export const getCookedSlips = ({
  limit = 10,
  startDate = new Date(Date.now() + 30 * 60000),
  endDate = null,
  page = 1,
  risk = null,
  search = null,
  strategies = null,
  exclude = null,
  startOdds,
  endCount,
}: {
  limit?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  page?: number;
  risk?: string | null;
  search?: string | null;
  strategies?: string[] | null;
  exclude?: string[] | null;
  startOdds?: string | null;
  endCount?: string | null;
}): (() => Promise<any>) => {
  const queryParams: Record<string, string | number> = {
    limit,
    page,
  };

  if (risk !== null) {
    queryParams.risk = risk;
  }

  if (startDate !== null) {
    let today = new Date();
    queryParams.startDate =
      new Date(startDate).toDateString() === today.toDateString()
        ? new Date(Date.now() + 30 * 60000).toISOString()
        : new Date(startDate).toISOString();
  }

  if (endDate !== null) {
    queryParams.endDate = new Date(endDate).toISOString();
  }

  if (search !== null) {
    queryParams.search = search;
  }

  if (startOdds !== null) {
    queryParams.startOdds = startOdds;
  }

  if (endCount !== null) {
    queryParams.endCount = endCount;
  }

  if (strategies !== null && strategies.length > 0) {
    queryParams.strategy = strategies.join(",");
  }

  if (exclude !== null && exclude.length > 0) {
    queryParams.exclude = exclude.join(",");
  }

  // @ts-ignore
  const queryString = new URLSearchParams(queryParams).toString();

  return () =>
    api
      .get(`/slips?${queryString}`)
      .then((res: AxiosResponse<any>) => res.data);
};

export const getUnlockedCookedSlips = ({
  limit = 10,
  startDate = null,
  endDate = null,
  page = 1,
  risk = null,
  search = null,
  strategies = null,
  exclude = null,
  startOdds,
  endCount,
}: {
  limit?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  page?: number;
  risk?: string | null;
  search?: string | null;
  strategies?: string[] | null;
  exclude?: string[] | null;
  startOdds?: string | null;
  endCount?: string | null;
}): (() => Promise<any>) => {
  const queryParams: Record<string, string | number> = {
    limit,
    page,
  };

  if (risk !== null) {
    queryParams.risk = risk;
  }

  if (startDate !== null) {
    queryParams.startDate = new Date(startDate).toISOString();
  }

  if (endDate !== null) {
    queryParams.endDate = new Date(endDate).toISOString();
  }

  if (search !== null) {
    queryParams.search = search;
  }

  if (startOdds !== null) {
    queryParams.startOdds = startOdds;
  }

  if (endCount !== null) {
    queryParams.endCount = endCount;
  }

  if (strategies !== null && strategies.length > 0) {
    queryParams.strategy = strategies.join(",");
  }

  if (exclude !== null && exclude.length > 0) {
    queryParams.exclude = exclude.join(",");
  }

  // @ts-ignore
  const queryString = new URLSearchParams(queryParams).toString();

  return () =>
    api
      .get(`/slips/user?${queryString}`)
      .then((res: AxiosResponse<any>) => res.data);
};

export const getSlipInfo = (slipId) => {
  return () => api.get(`/slips/${slipId}/info`).then((res) => res.data);
};

export const getSlipMatches = (slipId) => {
  return () => api.get(`/slips/${slipId}/matches`).then((res) => res.data);
};

export const unlockSlip = (slipId) => {
  return api.post("/slips/unlock", { slipId });
};
