import React, { createContext, useState, useEffect } from "react";

import Notify from "../components/Notifications/Notify";

import { useNavigate } from "react-router-dom";

import {
  signInWithGoogle,
  signInWithFacebook,
  signInWithTwitter,
  analytics,
} from "../firebase";

import { setUserId } from "firebase/analytics";

// rq
import { useQuery, useQueryClient } from "@tanstack/react-query";

import services from "../services";

export const UserContext = createContext();

const UserFromLS = JSON.parse(localStorage.getItem("user") || null);

const UserContextProvider = (props) => {
  const queryClient = useQueryClient();

  // retrieving values from local storage
  const [user, setUser] = useState(UserFromLS);

  //
  const { data: userData, refetch } = useQuery({
    queryKey: ["user data"],
    queryFn: services.user(),
    enabled: Boolean(user?.accessToken),
    staleTime: 1440 * (60 * 1000), // An entire day
    cacheTime: 1450 * (60 * 1000), // A little more than an entire day
  });

  // USER UTIL FUNCTIONS
  const getUserData = () => {
    refetch();
  };

  const navigate = useNavigate();

  // ANALYTICS: Adding User ID to Logs
  useEffect(() => {
    if (user) {
      setUserId(analytics, user.uid);
    }
  }, [user]);

  // FIREBASE AUTH FUNCTIONS

  const handleGoogleAuth = (newUser = false) => {
    signInWithGoogle()
      .then((userCredential) => {
        const user = userCredential.user;
        setUser({
          accessToken: user.accessToken,
          email: user.email,
          verified: user.emailVerified,
          uid: user.uid,
          phoneNumber: user.phoneNumber,
          displayName: user.displayName,
          photoURL: user.photoURL,
          signedIn: true,
          tokenManager: user.stsTokenManager,
        });
        afterAuth(newUser);
      })
      .catch((err) => {
        // Notify("error", "Error occured");
      });
  };

  // twitter
  const handleTwitterAuth = (newUser = false) => {
    signInWithTwitter()
      .then((userCredential) => {
        const user = userCredential.user;

        setUser({
          accessToken: user.accessToken,
          email: user.email,
          verified: user.emailVerified,
          uid: user.uid,
          phoneNumber: user.phoneNumber,
          displayName: user.displayName,
          photoURL: user.photoURL,
          signedIn: true,
          tokenManager: user.stsTokenManager,
        });
        afterAuth(newUser);
      })
      .catch((err) => {
        // Notify("error", "Error occured");
      });
  };

  // facebook
  const handleFacebookAuth = (newUser = false) => {
    signInWithFacebook()
      .then((userCredential) => {
        const user = userCredential.user;
        setUser({
          accessToken: user.accessToken,
          email: user.email,
          verified: user.emailVerified,
          uid: user.uid,
          phoneNumber: user.phoneNumber,
          displayName: user.displayName,
          photoURL: user.photoURL,
          signedIn: true,
          tokenManager: user.stsTokenManager,
        });
        afterAuth(newUser);
      })
      .catch((err) => {
        // Notify("error", "Error occured");
      });
  };

  const addReferral = () => {
    let code = JSON.parse(sessionStorage.getItem("referral"));
    if (code) {
      services
        .addReferral(code)
        .then((res) => {
          Notify("success", "Referral added!");
        })
        .catch((e) => {
          Notify("error", "Error adding referral");
        });
    }
  };

  // auth helper
  const afterAuth = (newUser) => {
    if (newUser) {
      Notify("success", "Created account successfully");
      addReferral();
    } else {
      Notify("success", "Logged In");
    }
    // Redirect to Application
    navigate("/dashboard");
  };

  // mobile menu toggle
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const signOut = () => {
    queryClient.removeQueries({ queryKey: ["user data"] });

    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  // user data
  useEffect(() => {
    if (Boolean(userData)) {
      setUser((prevState) => ({ ...prevState, ...userData }));
    }
  }, [userData]);

  // update user whenever user info is updates
  useEffect(() => {
    //
    localStorage.setItem("user", JSON.stringify(user));

    // add user info from api.soccersm.com
  }, [user, userData]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        signOut,
        getUserData,
        showMobileMenu,
        setShowMobileMenu,
        handleFacebookAuth,
        handleGoogleAuth,
        handleTwitterAuth,
        addReferral,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
